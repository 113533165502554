import logo from './logo.svg';
import user_avatar from './user-avatar2.png';
import frontside from './Frontside.jpg';
import backside from './Backside.jpg';
import email from './email.svg';
import github from './github.svg';
import linkedin from './linkedin.svg'
import './App.css';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
function App() {
    document.title = "Portfolio";
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }
  const { height, width } = useWindowDimensions();
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const [offsetY, setOffsetY] = useState(0);
  const handleScroll =() => {
      setOffsetY(window.pageYOffset);
  }
  let scrollPhase = 0
  if(offsetY/height>0.371){
    scrollPhase = 1
  }
  const avatarStyle ={
    transform: scrollPhase === 0? `scale(${1-(offsetY/height)*1.5})`:`scale(${1-(0.371)*1.5})`,
    top: scrollPhase === 0? `${30-(offsetY/height)*100}%` : `${30-(0.371)*100}%`,
    left: scrollPhase === 0? `${(offsetY/height)*-50+15}%` : `${(0.371)*-50+15}%`
  }
  const avatarStyleMobile ={
    width: '32%',
    border: 'solid 10px',
    borderColor: '#ebae14',
    borderRadius: '50%'
    // transform: scrollPhase === 0? `scale(${1-(offsetY/height)*1.7})`:`scale(${1-(0.371)*1.7})`,
    // top: scrollPhase === 0? `${30-(offsetY/height)*90}%` : `${30-(0.371)*90}%`,
    // left: scrollPhase === 0? `${(offsetY/height)*-110+32}%` : `${(0.371)*-110+32}%`
  }
  const nameStyle ={
    transform: scrollPhase === 0? `scale(${1-(offsetY/height)*1.7})`:`scale(${1-(0.371)*1.7})`,
    top: scrollPhase === 0? `${36-(offsetY/height)*100}%` : `${36-(0.371)*100}%`,
    left: scrollPhase === 0? `${(offsetY/height)*-105+35}%` : `${(0.371)*-105+35}%`
  }
  const nameStyleMobile ={
    fontSize: '250%',
    paddingLeft: '2%',
    // transform: scrollPhase === 0? `scale(${1-(offsetY/height)*1.5})`:`scale(${1-(0.371)*1.5})`,
    // top: scrollPhase === 0? `${50-(offsetY/height)*131}%` : `${50-(0.371)*131}%`,
    // left: scrollPhase === 0? `${(offsetY/height)*-34+10}%` : `${(0.371)*-34+10}%`
  }
  const webStyle ={
    transform: scrollPhase === 0? `scale(${1-(offsetY/height)*1.1})`:`scale(${1-(0.371)*1.1})`,
    top: scrollPhase === 0? `${50-(offsetY/height)*110}%` : `${50-(0.371)*110}%`,
    left: scrollPhase === 0? `${(offsetY/height)*-137+68}%` : `${(0.371)*-137+68}%`
  }
  const webStyleMobile ={
    fontSize: '150%',
    // transform: scrollPhase === 0? `scale(${1-(offsetY/height)*1.4})`:`scale(${1-(0.371)*1.4})`,
    // top: scrollPhase === 0? `${57-(offsetY/height)*140}%` : `${57-(0.371)*140}%`,
    // left: scrollPhase === 0? `${(offsetY/height)*-19+30}%` : `${(0.371)*-19+30}%`
  }
  const tabStyle ={
    color: '#f7f7f234'
  }
  const tabStyleMobile ={
    color: '#f7f7f234'
  }
  const tabSelectedStyle = {
  }
  const tabSelectedStyleMobile = {
  }
  const appDivStyle = {
    height: '100%',
    opacity: offsetY<(height*0.74)? `${offsetY/(height*0.74)}` : offsetY> 1000? `${(2200/offsetY)-1.2}` : '1' ,
  }
  const appDivStyleMobile = {
    // height: '80vh',
    opacity: offsetY<(height*0.74)? `${offsetY/(height*0.74)}` : offsetY> 1000? `${(2200/offsetY)-1.2}` : '1' ,
  }
  const frontsideStyle = {
    marginRight: '2%',
    transform: (offsetY/4+180)>=360? `rotateY(0deg)`  : `rotateY(${offsetY/4+180}deg)` ,
    height: '70%',
    position: 'relative',
    borderRadius: '18px',
    border: 'solid 3px',
    borderColor: 'white',
  }
  console.log(offsetY/4+180)
  const frontsideStyleMobile = {
    // marginLeft: '6%',
    marginRight: '4%',
    width: '40%',
    transform: (offsetY/4+180)>=360? `rotateY(0deg)`  : `rotateY(${offsetY/4+180}deg)` ,
    position: 'relative',
    borderRadius: '18px',
    border: 'solid 3px',
    borderColor: 'white',
  }
  const backsideStyle = {
    transform: (offsetY/4+180)>=360? `rotateY(0deg)`  : `rotateY(${offsetY/4+180}deg)` ,
    marginRight: '4%',
    height: '70%',
    position: 'relative',
    borderRadius: '18px',
    border: 'solid 3px',
    borderColor: 'white',
  }
  const backsideStyleMobile = {
    transform: (offsetY/4+180)>=360? `rotateY(0deg)`  : `rotateY(${offsetY/4+180}deg)` ,
    width: '40%',
    position: 'relative',
    borderRadius: '18px',
    border: 'solid 3px',
    borderColor: 'white',
  }
  const appText = {
    position: 'relative',
    top: '10%',
    fontSize: '700%',
  }
  const appTextMobile = {
    position: 'relative',
    top: '-2%',
    fontSize: '200%',
  }
  const appConText = {
    paddingTop: '3%',
    position: 'relative',
    textAlign: 'right',
    // top: '10%',
    fontSize: '200%',
    lineHeight: '150%'
  }
  const appConTextMobile = {
    paddingRight: '3%',
    position: 'relative',
    textAlign: 'right',
    // top: '10%',
    fontSize: '100%',
    lineHeight: '150%'
  }
  const appHeaderMobile = {
    // width: '90%'
  }
  useEffect(()=> {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <div className="App">
      {!isPortrait? 
      <div>
      <div className="NavBar">
        <button><text className="ButtonText" style={window.pageYOffset<=height-100? tabSelectedStyle:tabStyle} onClick={()=>window.scrollTo(0, 0)}>About me</text></button>
        <button><text className="ButtonText" style={window.pageYOffset>height-100 && window.pageYOffset<=height*2-69 ? tabSelectedStyle:tabStyle} onClick={()=>window.scrollTo(0, height)}>Projects</text></button>
        <button><text className="ButtonText" style={window.pageYOffset>height*2-100? tabSelectedStyle:tabStyle} onClick={()=>window.scrollTo(0, height*2.2)}>Contact</text></button>
      </div>
      <header className="App-header">
        <div className="SubHeader">
        <img src={user_avatar} style={avatarStyle} className="Avatar" alt="Profile picture" />
        <text style={nameStyle} className="Name">Artūrs Strucinskis</text>
        <text style={webStyle} className="SubName">Web Developer</text>
        </div>
      </header>
      <div className="App-header2">
      <div className="App-header3" style={appDivStyle}>
      <img src={frontside} style={frontsideStyle} alt="Profile picture" />
      <img src={backside} style={backsideStyle} alt="Profile picture" />
      <div style={{height:'100%'}}>
      <p style={appText}> Minimalistic<br></br> weather app<br></br> </p>
      <div style={appConText}>
      <p className="righText">Built with React Native<br></br>Uses OpenWeather API</p>
      <a href="https://github.com/S-Arturs/Weather-App" target="_blank">GitHub link</a>
      </div>
      </div>
      </div>
      </div>
      <div className="App-header4">
        <div>
        <p><a><img src={email} className="Socials"></img>  strucinskisarturs@gmail.com</a></p>  
        <p><a href="https://www.linkedin.com/in/art%C5%ABrs-strucinskis-35544221a/"><img src={linkedin} className="Socials"></img> linkedin.com/in/artūrs-strucinskis-35544221a</a></p>       
        <p><a href="https://github.com/S-Arturs"><img src={github} className="Socials"></img>  github.com/S-Arturs</a></p>  
        </div>
      </div>
      </div> 
      
      : 

      <div>
      <header style={appHeaderMobile} className="App-header">
        <div className="App-headerMobile">
        <img src={user_avatar} style={avatarStyleMobile} alt="Profile picture" />
        <text style={nameStyleMobile} >Artūrs Strucinskis <br></br><div style = {{fontSize: '50%'}}>Web Developer</div></text>
        </div>
        {/* <text style={webStyleMobile} >Web Developer</text> */}
      </header>
      <div className="App-header2mobile">
      <div className="App-header3mobile" style={appDivStyleMobile}>
      <img src={frontside} style={frontsideStyleMobile} alt="Profile picture" />
      <img src={backside} style={backsideStyleMobile} alt="Profile picture" />
      <p style={appTextMobile}> Minimalistic weather app<br></br> </p>
      <div style={appConTextMobile}>
      <p className="righText">Built with React Native<br></br>Uses OpenWeather API</p>
      <a href="https://github.com/S-Arturs/Weather-App" target="_blank">GitHub link</a>
      </div>
      </div>
      <p className="ButtonTextMobile">{offsetY<height-50? '': offsetY<(height -50) * 2? 'Projects': 'Contact'}</p>
      </div>
      <div className="App-header4Mobile">
      <div style={{display:'flex', flexDirection:'column', justifyContent: 'center',alignItems: 'center'}}>
        <a className="a"><img src={email} className="SocialsMobile"></img>  <p>strucinskisarturs@gmail.com</p></a>  
        <br></br>
        <a className="a" href="https://www.linkedin.com/in/art%C5%ABrs-strucinskis-35544221a/"><img src={linkedin} className="SocialsMobile"></img> <p> linkedin.com/in/artūrs-strucinskis-35544221a </p></a>   
        <br></br>
        <a className="a" href="https://github.com/S-Arturs"><img src={github} className="SocialsMobile"></img> <p>  github.com/S-Arturs </p></a> 
        </div>
      </div>
      </div>}
    </div>
  );
}



export default App;
